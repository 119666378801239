//@import "../printcss/style.min";
//上書き用
html,body,.index-mv{
    width: 1240px;
}
.header-contact-wrap{
    padding-left: 380px;
}

.main-slider {
    .owl-nav {
        margin-top: 25px;

        & [class*='owl-'] {
            top: auto;
            //bottom:15px;
            bottom: -7px;
            border: none;
        }
    }
.owl-prev {
    left: calc(50% - 120px);
}

.owl-next {

    right: calc(50% - 120px);
}     
}

.index-main,
.page-main{
    margin-top: 0;
    padding-top: 80px;
    overflow-x: hidden;
}

.pickup-slider{

    display: flex !important;
    visibility: visible !important;
    opacity: 1 !important;
    &>*{
        padding: 0 4px;
    }
}
.index-corporate-data{
    right: 668px;
}
$bg-color:#f4f5f7;
$title-list:corporate,philosophy,message,outline,history,30th,factory,factory-honsha,factory-nankoku,media,product,ajinori,gokusen,umasugiru,gentei,ajihana,gokusyun,yakinori,set,gift,deliciousness,feature,process,storage,dashi,ajidashi,mutenka,genen,quality,line;    
@each $image in $title-list {
  .page-title.#{$image} {
      background-image: url(../img/#{$image}/mv_pc.jpg),linear-gradient(180deg, #fff 0%, #fff 15%, $bg-color 15%, $bg-color 100%)  !important;       
  }
}
  .page-title.history-30th {
      background-image: url(../img/30th/mv_pc.jpg),linear-gradient(180deg, #fff 0%, #fff 15%, $bg-color 15%, $bg-color 100%)  !important;    
      }      
.ariake-bg,
.facility-bg{
    width: 1240px;
}      
.js_print{
    display: block;
}
header,
.fix-header{
    position: absolute;
}
.top-btn {
display: none !important;;    
}
.btn-return:before{
right: auto;    
}
body,p,h1,h2,h3,h4,table,span,div{
    //font-family: sans-serif !important;
}
.js_fadein,
.event-list-item,.bloglist,.workslist>div,.js_fadein-timeline>div{
    opacity: 1 !important;
    transform: matrix(1, 0, 0, 1, 0, 0) !important;
}

